<template>
<div v-if="!loader">
  <div id="app">
    <div >
      <!-- <PopUp/> -->
      <Header v-if="!$route.meta.landings"/>
      <Banner v-if="!$route.meta.hideBanner" :pageTitle="$route.meta.pageTitle" style="background-image: url(../images/background/fondo-obscuro-hexagonal.png);"/>
      <router-view/>
      <Footer/>
    </div>
    <div>
      <ClicktoCall/>
    </div>
  </div>
</div>
<Preloader v-else/>
</template>

<script>
// @ is an alias to /src
  import Footer from '@/components/layout/Footer.vue'
  import Popup from '@/components/layout/Popup.vue'
  import Banner from '@/components/layout/Banner.vue'
  import Header from '@/components/layout/Header.vue'
  import Zadarma from '@/components/layout/Zadarma.vue'
  import Preloader from "@/components/layout/Loader.vue"
  import ClicktoCall from "@/components/layout/ClicktoCall.vue"
  // import {ref} from 'vue';
export default {
  name: 'Home',
  components: {
    Popup,
    Preloader,
    Header,
    Banner,
    Footer,
    Zadarma,
    ClicktoCall
  },
    data(){
      return{
        loader:true,
        popup:true,
        routeName: '',
        myZadarmaCallmeWidget13312: ''
      }
    },
    mounted(){
      window.scrollTo(0, 0)
    },
  // watch:{
  //   routeName: function () {
  //       window.scrollTo(0,10);
  //   }
  // },
  computed:{
    
  },
  created(){
    setTimeout(()=>{ this.popup=false},20000);
    setTimeout(()=>{
          this.loader=false
        },2000);
  },
  methods:{
  //     scrollBehavior (to, from, savedPosition) {
  //         if (to.hash) {
  //             return {
  //                 selector: to.hash,
  //                 behavior: 'smooth',
  //             }
  //         }
  //     }
  }
}

</script>

<style lang="scss">
#app {
  font-family: Roboto;
  text-align: center;
  color: #000;
  background-color: #f1f1f1 !important;
  overflow: hidden;
}

.watermark{ 
    background: transparent url(../public/images/logo/logo-voicetelecom-aplicacion-tranparente-blanco.png) no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    -o-object-position: center;
    object-position: center;
    position: absolute;
    width: 36%;
    right: 33%;
  }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 590px) {
    .watermark{ 
    width: 50%;
    right: 28%;

  }
}

  .watermark{
    // opacity: 0.5;
  }

</style>
