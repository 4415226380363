var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12 col-12 pb-3"},[_c('div',{staticClass:"price-table"},[_vm._m(0),_vm._m(1),_c('p',[_vm._v(_vm._s(_vm.$t("pages.get.target.type_alarm")))]),_c('a',{staticClass:"lnk-default",attrs:{"href":"https://buy.stripe.com/dR628ubcNdN851u9AO","target":"_blank","title":""}},[_vm._v(_vm._s(_vm.$t("pages.get.target.get_button")))]),_c('div',{staticClass:"clearfix"})])]),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12 col-12 pb-3"},[_c('div',{staticClass:"price-table"},[_vm._m(2),_vm._m(3),_c('p',[_vm._v(_vm._s(_vm.$t("pages.get.target.type_alarm")))]),_c('a',{staticClass:"lnk-default",attrs:{"href":"https://buy.stripe.com/14k7sOcgR9wS1Pi3cr","target":"_blank","title":""}},[_vm._v(_vm._s(_vm.$t("pages.get.target.get_button")))]),_c('div',{staticClass:"clearfix"})])]),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12 col-12 pb-3"},[_c('div',{staticClass:"price-table"},[_vm._m(4),_vm._m(5),_c('p',[_vm._v(_vm._s(_vm.$t("pages.get.target.type_alarm")))]),_c('a',{staticClass:"lnk-default",attrs:{"href":"https://buy.stripe.com/5kA00mbcNfVgdy0aEU","target":"_blank","title":""}},[_vm._v(_vm._s(_vm.$t("pages.get.target.get_button")))]),_c('div',{staticClass:"clearfix"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-head"},[_c('div',[_c('img',{attrs:{"src":require("../../../public/images/services/icono-sistema-de-alarmas.png"),"alt":"icono sistema de alarmas y monitoreo"}})]),_c('div',{staticClass:"mt-5"},[_c('h3',[_vm._v("550,00 MXN")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v(" BeSafe "),_c('span',[_vm._v("Básico")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-head"},[_c('div',[_c('img',{attrs:{"src":require("../../../public/images/services/icono-sistema-de-alarmas.png"),"alt":"icono sistema de alarmas y monitoreo"}})]),_c('div',{staticClass:"mt-5"},[_c('h3',[_vm._v("600,00 MXN")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v(" BeSafe "),_c('span',[_vm._v(" Estándar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-head"},[_c('div',[_c('img',{attrs:{"src":require("../../../public/images/services/icono-sistema-de-alarmas.png"),"alt":"icono sistema de alarmas y monitoreo"}})]),_c('div',{staticClass:"mt-5"},[_c('h3',[_vm._v("650,00 MXN")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v(" BeSafe "),_c('span',[_vm._v("Premium")])])
}]

export { render, staticRenderFns }