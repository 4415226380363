<template>
    <div v-if="!anuncio.navidad" class="overlay active">
        <div class="popup active text-center" id="popup">
            <label class="btn-cerrar-popup">
                <input v-model="anuncio.navidad" type="checkbox" @click="changeForm" >
                <i class="fas fa-times"></i>
            </label>
            <div class="title">
                <h3>{{$t("components.announcement.title")}}</h3>
            </div>
            <h4>{{$t("components.announcement.description")}}
            </h4>
            <p>Todas las consultas sobre saldos y facturación puede revisarlas en su portal o las puede enviar a <a href="mailto:administración@voicetelecom.net" style="color:#009c39">administración@voicetelecom.net</a> , donde serán atendidas a partir del día 8 de Febrero.</p>

            <p>En caso de fallas en el servicio por favor enviar correo <a href="mailto:soporte@voicetelecom.net" style="color:#009c39">soporte@voicetelecom.net</a>  o enviar al whatsapp <a href="https://api.whatsapp.com/send?text=Buen día, tengo problemas con mi servicio&phone=52 999-256-6850" target="_blank" style="color:#009c39"> 998 256 6850 </a>
            </p>
            
            <img src="/images/logo/logo-voicetelecom-aplicacion-tranparente-blanco.png" class="logo mb-3" alt="logo voicetelecom aplicación transparente blanco">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            anuncio:{
                navidad: false
            }
        }
    },
    computed:{
        
    },
    methods:{
        changeForm() {
            for(let i in this.anuncio){
                if(i!='navidad')
                this.anuncio[i]=""
            }
        },
    }
}

</script>

<style scoped>
.logo{
    margin-top: 20px;
    height: 50px;
    text-align: center;
    position: relative;
    right: -21%;
    filter: drop-shadow(6px 5px 2px rgba(0, 0, 0, 0.7))
}
.overlay {
	background: rgba(0,0,0,.5);
    color: #fff;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	display: flex;
	visibility: hidden;
    z-index: 5;
}
.overlay.active {
	visibility: visible
}
.popup {
	background-image: url(../../../public/images/background/aviso.png);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.6);
	border-radius: 5px;
    position: center;
    background-repeat: no-repeat;
    background-size: cover;
	font-family: 'Montserrat', sans-serif;
	padding: 40px;
	text-align: center;
	width: 750px;
	transition: .3s ease all;
	transform: scale(0.7);
	opacity: 0;
}
.popup .btn-cerrar-popup {
    position: relative;
    max-width: 10%;
    padding: 10px;
    left:92%;
	font-size: 19px;
	line-height: 16px;
	display: block;
	text-align: right;
	transition: .3s ease all;
	color: #BBBBBB;
    z-index: 5;
}
.popup .btn-cerrar-popup:hover {
	color: #900103;
}
.popup h3 {
	font-size: 33px;
	font-weight: 600;
	margin-bottom: 10px;
	opacity: 0;
    background-color: white;
    color:#900103;
    max-width: 150px;
    text-align: center;
}
.title{
    text-transform: uppercase;
    position: relative;
    left: 31%;
    top: -40px;
    transform: rotate(356deg);
}
.popup h4 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 15px;
	opacity: 0;
    text-shadow: 4px 3px 2px black;
}
.popup p {
	font-size: 18px;
    text-align: center;
    color: #fff;
	font-weight: 600;
	margin-bottom: 10px;
    text-shadow: 4px 3px 2px black;
}
/* ------------------------- */
/* ANIMACIONES */
/* ------------------------- */
.popup.active {	transform: scale(1) !important; opacity: 1; }
.popup.active h3 { animation: entradaTitulo .8s ease .5s forwards; }
.popup.active h4 { animation: entradaSubtitulo .8s ease .5s forwards; }
.popup.active p { animation: entradaSubtitulo .8s ease .5s forwards; }
.popup.active .contenedor-inputs { animation: entradaInputs 1s linear 1s forwards; }

@keyframes entradaTitulo {
	from {
		opacity: 0;
		transform: translateY(-25px);
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes entradaSubtitulo {
	from {
		opacity: 0;
		transform: translateY(25px);
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes entradaInputs {
	from { opacity: 0; }
	to { opacity: 1; }
}

@media only screen and (max-width: 427px){
    .popup {
    background-position: right;
    background-size: cover;
}
    .popup h4 {
	font-size: 16px;
	margin-bottom: 15px;
}
}

@media only screen and (max-width: 396px){
    .logo{
        margin-top: 20px;
        height: 30px;
        text-align: center;
        position: relative;
        right: -21%;
        filter: drop-shadow(6px 5px 2px rgba(0, 0, 0, 0.7))
    }
}
</style>