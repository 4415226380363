<template>
    <section class="pager-sec overlay-dark" >
        <div class="container">
            <div class="pager-sec-details">
                <h2>{{$t(`${pageTitle}`)}}</h2>
                <p v-if="nameRoute==='Support'" class="mb-0 mt-0">{{$t("routes.support.breadcrumb")}}</p>
            </div><!--pager-sec-details end-->
        </div>
    </section><!--pager-sec end-->
</template>

<script>
export default {
    computed:{
        nameRoute(){
            return this.$route.name;
        }
    },
    props:{
        pageTitle:{
            type:String,
            required:true
        }
    },
    
}
</script>

<style scoped>
.breadcrumb a{
    text-decoration: none;
}

.pager-sec {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 88px 0 64px;
    background-position: center;
}

p{
    color: #d3d3d3;
    font-size: 25px;
    
}
</style>