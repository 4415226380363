<template>
  <div class="wrapper">
    <header>
      <div class="black">
        <div class="container">
          <div class="header-content top-nav">
            <div class="contact">
              <ul class="contact-info">
                <li>
                  <i class="fa fa-envelope"></i>
                  <span>contacto@voicetelecom.net</span>
                </li>
                <!-- <li>
									<i class="fa fa-map-marker-alt"></i>
									<span>Melbourne VIC 3000, Australia</span>
								</li> -->
              </ul>
            </div>
            <div class="social">
              <ul class="contact-info">
                <li>
                  <a
                    href="https://www.facebook.com/VoiceTelecomEmpresarial"
                    title=""
                    target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/voicetelecom/"
                    title=""
                    target="_blank"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCIx8psvYEd8NndtYiXxwWng"
                    title=""
                    target="_blank"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.pinterest.com.mx/voicetelecomnet/"
                    title=""
                    target="_blank"
                    ><i class="fab fa-pinterest-p"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/voicetelecomhogar/"
                    title=""
                    target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="clearfix"></div>
          </div>
          <!--header-content end-->
        </div>
      </div>
      <div class="bottom-header">
        <div class="container">
          <div class="header-content">
            <div class="logo">
              <router-link to="/" title="">
                <img
                  src="/images/logo/logo-voicetelecom-aplicación-doble-color-verde.png"
                  class="logo"
                  alt="logo voicetelecom aplicación doble color verde"
                />
              </router-link>
            </div>
            <!--logo end-->
            <a href="#" title="" class="menu-btn"><i class="fa fa-bars"></i></a>
            <Zadarma/>
            <nav>
              <ul class="header-top">
                <li>
                  <router-link
                    :class="[nameRoute != 'Home' ? 'no' : 'active']"
                    to="/"
                    title=""
                    >{{ $t("layout.nav.home") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="[nameRoute != 'About' ? 'no' : 'active']"
                    to="/nosotros"
                    title=""
                    >{{ $t("layout.nav.about") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="[nameRoute != 'Services' ? 'no' : 'active']"
                    to="/servicios"
                    title=""
                    >{{ $t("layout.nav.services") }}</router-link
                  >
                </li>
                <!-- <li>
                  <router-link
                    :class="[nameRoute != 'InfoVoiceIp' ? 'no' : 'active']"
                    to="/infoVoice"
                    title=""
                    >{{ $t("layout.nav.InformacionVoice") }}</router-link
                  >
                </li> -->
                <!-- <li><a href="#" title="">Pages</a>
									<ul>
										<li><a href="#" title="">Shop</a>
											<ul>
												<li><a href="shop.html" title="">Shop</a></li>
												<li><a href="shop-details.html" title="">Shop Details</a></li>
											</ul>
										</li>
										<li><a href="gallery.html" title="">Gallery</a></li>
										<li><a href="reference.html" title="">Reference</a></li>
										<li><a href="project-details.html" title="">Project Details</a></li>
										<li><a href="prices.html" title="">Prices</a></li>
										<li><a href="#" title="">Team</a>
											<ul>
												<li><a href="team.html" title="">Team</a></li>
												<li><a href="team-details.html" title="">Team Details</a></li>
											</ul>
										</li>
									</ul>
								</li> -->
                <!-- <li><a href="#" title="">Blog</a>
									<ul>
										<li><a href="blog.html" title="">Blog Style One</a></li>
										<li><a href="blog2.html" title="">Blog Style Two</a></li>
										<li><a href="blog-single.html" title="">Blog Details</a></li>
									</ul>
								</li> -->
                <li>
                  <router-link
                    :class="[nameRoute != 'Support' ? 'no' : 'active']"
                    to="/soporte"
                    title=""
                    >{{ $t("layout.nav.support") }}</router-link
                  >
                </li>
                <!-- <li><router-link to="/blog" title="">{{$t('layout.nav.blog')}}</router-link></li> -->
                <li class="contrataciones">
                  <router-link
                    :class="[nameRoute != 'Team' ? 'no' : 'active']"
                    to="/contrataciones"
                    title=""
                    >{{ $t("layout.nav.get") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="[nameRoute != 'Contact' ? 'no' : 'active']"
                    to="/contacto"
                    title=""
                    >{{ $t("layout.nav.contact") }}</router-link
                  >
                </li>
                <li class="entrar">
                  <a
                    href="https://wisp.voicetelecom.net/cliente/login"
                    class="onlogin"
                    target="_blank"
                    title=""
                    >{{ $t("layout.nav.login") }}</a
                  >
                </li>
                <li><LocaleSwitcher /></li>
              </ul>
            </nav>
            <!--navigation end-->
            <div class="clearfix"></div>
          </div>
          <!--header-content end-->
        </div>
      </div>
      <!--bottom-header end-->
    </header>
    <!--header end-->

    <div class="mobile-menu">
      <ul>
        <li>
          <router-link class="active" to="/" title="">{{
            $t("layout.nav.home")
          }}</router-link>
        </li>
        <li>
          <router-link to="/nosotros" title="">{{
            $t("layout.nav.about")
          }}</router-link>
        </li>
        <li>
          <router-link to="/servicios" title="">{{
            $t("layout.nav.services")
          }}</router-link>
        </li>
        <!-- <li>
          <router-link to="/infoVoice" title="">{{
            $t("layout.nav.InformacionVoice")
          }}</router-link>
        </li> -->
        <li>
          <router-link to="/soporte" title="">{{
            $t("layout.nav.support")
          }}</router-link>
        </li>
        <!-- <li><router-link to="/blog" title="">{{$t('layout.nav.blog')}}</router-link></li> -->
        <li>
          <router-link to="/contrataciones" title="">{{
            $t("layout.nav.get")
          }}</router-link>
        </li>
        <li>
          <router-link to="/contacto" title="">{{
            $t("layout.nav.contact")
          }}</router-link>
        </li>
        <li>
          <a
            href="https://wisp.voicetelecom.net/cliente/login"
            target="_blank"
            title=""
            >{{ $t("layout.nav.login") }}</a
          >
        </li>

        <li><LocaleSwitcher /></li>
      </ul>
    </div>
    <!--mobile-menu end-->
  </div>
</template>

<script>
// @ is an alias to /src
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
import Zadarma from "./Zadarma.vue";

export default {
  name: "Home",
  components: {
    // ContactFrom,
    LocaleSwitcher,
    Zadarma
},
  computed: {
    nameRoute() {
      return this.$route.name;
    },
  },
  mounted() {
    $(".menu-btn").on("touchstart", function() {
      $(this).toggleClass("active");
      $(".mobile-menu").toggleClass("show");
    });
  },
};
</script>

<style scoped>
.header-top {
  padding-top: 10px;
  margin-bottom: 0 !important;
}
.bg-light {
  background-color: #fff !important;
}

.logo {
  height: 95px;
}

.contrataciones {
  padding: 0 !important;
}
.entrar {
  padding: 0 !important;
}

.oncontrata {
  background-color: #009c39;
  color: #fff;
  padding: 15px 20px;
  border-radius: 30px;
}

.oncontrata:hover {
  background-color: #115740;
  color: #fff;
  padding: 15px 20px;
  border-radius: 30px;
}
.activeOncontrata {
  background-color: #115740;
}
.onlogin {
  border: 2px solid #212529;
  padding: 15px 20px;
  border-radius: 30px;
}
.onlogin:hover {
  background-color: #115740;
  border: 2px solid #115740;
  color: #fff;
  padding: 15px 20px;
  border-radius: 30px;
}

@media only screen and (max-width: 1200px) {
  .logo {
    height: 70px;
  }
}

@media screen and (min-width: 1201px) {
  .container {
    max-width: 1201px;
  }
}

.navbar-collapse {
  padding-right: 15%;
  flex-grow: 0;
  align-items: center;
}

.navbar {
  padding: 0px 80px;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 600;
}

.nav-item {
  padding: 10px !important;
}

a {
  color: #000;
  text-decoration: none;
}

.dropdown-menu {
  background-color: #009c39;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 600;
  color: #212529;
  /* text-align: inherit; */
  /* text-decoration: none; */
  /* white-space: nowrap; */
  /* background-color: transparent; */
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: #009c39;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000; /* display: none; */
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu {
  top: 90% !important;
  left: 0;
  margin-top: 0.125rem;
}

.top-nav {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-around;
  color: #fff;
}

.contact-info i {
  color: #fff;
}
.contact-info i:hover {
  color: #009c39 !important;
}
</style>
