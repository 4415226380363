<template>
    <div>
      <Banner :pageTitle="$route.meta.pageTitle" style="background-image: url(/images/background/fondo-obscuro-hexagonal.png); " />
      <div class="content-contact-page" data-aos="fade-up">
        <ContactForm2/>
      </div>
    </div>
</template>



<script>
// @ is an alias to /src
import ContactForm2 from '@/components/contact/ContactForm2'
import Banner from '@/components/layout/Banner'

export default {
  components: {
    ContactForm2,
    Banner

  }
}
</script>

<style scoped>
.home{
  background-color: #f5f5f5 !important;
}
.content-contact-page{
    width: 100%;
    background-position: center;
    background-size: cover;
    min-height: 500px; 
    background-image: url("../../../public/images/background/fondo-transparente-vertical-hexagonal.png");
    background-repeat: no-repeat;
    position: relative;
    z-index: 0; 
}

.content-contact-page::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(to right top, #05193799, #004d7a99, #00879399, #00bf7299, #a8eb1299); */
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
}
</style>