<template>
    <div>
        <Blog/>
    </div>
</template>

<script>
import Blog from '@/components/layout/Blog.vue' 
export default {
    components:{
        Blog
    },
}
</script>