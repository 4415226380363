<template>  
    <div>
        <NotFound code="404" />
    </div>
</template>

<script>
import NotFound from '@/components/layout/NotFound.vue';
export default {
  components:{
    NotFound
  }
}
</script>