<template>
<div>
    <Banner  :pageTitle="$route.meta.pageTitle" style="background-image: url(/images/fondo-obscuro-hexagonal.png);" />
    <section class="page-content">
        <div class="container">
            <div class="reference-page">
                <div class="ref-item">
                    <div class="ref-thumb">
                        <img class="logo" src="../../../public/images/logo/logo-voicetelecom-aplicación-fonde-color-verde.jpg" alt="logo voicetelecom aplicación fonde color verde">
                    </div>
                    <div class="ref-info">
                        <h3>Aviso de Privacidad</h3>
                        <span>Voice Telecom</span>
                        <p>Esta es la política de privacidad de <span>“Voice Telecomunications de México SA de CV“ , VoiceTelecom</span>, con domicilio en Calle Bilbao no. 9 M.2 L.2 Reg.97 Benito Juarez Quintana Roo; incluyendo el sitio así como todos sus productos y servicios relacionados. Describe la información que se recaba como parte de la operación normal así como el uso y medidas con las cuales es tratada.</p>
    
                        <p>En esta política se utiliza el término “Usuario” para los clientes de <span>VoiceTelecom</span>  así como sus visitantes del sitio incluyendo con todos sus servicios y áreas de interacción. En todo momento, toda la información recabada será utilizada única y exclusivamente por <span>VoiceTelecom</span> . Sobre tu información personal.</p>
    
                        <p><span>VoiceTelecom</span>  puede solicitar información que identifique a sus usuarios personalmente cuando se considere necesario, misma información que será utiliza con propósitos de contacto o para manejo general, misma que podrá ser modificada por los usuarios en cualquier momento a discreción de los mismos. Generalmente, dicha información incluye datos de contacto como nombre personal o de la empresa y/o negocio del usuario, teléfono y correo electrónico.</p>
    
    
                        <p class="widget-title">Utilización de tu información personal</p> 
                        <p><span>VoiceTelecom</span>  utiliza la información del usuario para 3 propósitos principales:
                        </p>
                        <ul>
                            <li> Para que el usuario haga uso del sitio, las aplicaciones y servicios relacionados, mejorando la experiencia y facilidad de uso para el mismo.</li>
                            <li> Para entregar al usuario información de su interés.</li>
                            <li> Para notificar al usuario y alertarle cuando sea necesario.</li>
                        </ul>
    
                        <p>En caso de que no desee que sus datos personales sean tratados para estos fines adicionales, usted puede presentar desde este momento un escrito a nuestro correo electrónico info@voicetelecom.net, manifestando lo anterior.</p>
    
                        <p>La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>
    
                        <p><span>VoiceTelecom</span>  utiliza el correo electrónico como base para la comunicación con sus usuarios, pudiendo estos últimos limitar y/o restringir la comunicación por este medio haciéndolo expreso hacia <span>VoiceTelecom</span> </p>
    
                        <p><span>VoiceTelecom</span>  podrá divulgar la información de los usuarios única y exclusivamente y sin notificación o consentimiento cuando la ley así lo requiera o en la creencia de buena fe de que esa acción es necesaria para (a) hacer cumplir las leyes aplicables, (b) proteger la propiedad y los derechos de <span>VoiceTelecom</span>  o (c) para actuar en situaciones críticas para proteger la seguridad de los usuarios, sus sitios, o el público en general.</p>
    
                        <p class="widget-title">Seguridad de tu información</p>
    
                        <p><span>VoiceTelecom</span>  utiliza y utilizará las precauciones razonables para proteger por todos los medios la información personal con respecto a la pérdida, mal uso, acceso no autorizado, divulgación, alteración o destrucción. Estas medidas de seguridad incluyen el uso de contraseñas de acceso y operación, la restricción de acceso a información personal a solo las personas que sean propietarios y que la utilicen para propósitos legítimos, y los mecanismos más avanzados de encriptación de información en nuestros servidores así como de cifrado de comunicaciones.</p>
    
                        <p><span>VoiceTelecom</span>  utiliza y utilizará medidas precautorias bajo lo más altos controles de seguridad para salvaguardar toda la información de sus usuarios, incluyendo Certificados de Sello Digital (CSD), almacenándolos en repositorios independientes con encriptación, dándoles el uso correcto y sin compartirlos con terceros. El usuario podrá eliminar y/o reemplazar dichos archivos en el momento que así lo desee.</p>
    
                        <p class="widget-title">Integridad de tu información</p>
    
                        <p><span>VoiceTelecom</span>  utilizará la información de los usuarios para propósitos para los que fue registrada y autorizada por los mismos. Dicha información siempre será relevante para los propósitos y fines mencionados con anterioridad.
                        <span>VoiceTelecom</span>  permitirá a sus usuarios acceso a información personal y permitirá también agregar, corregir y/o eliminar la información cuando lo requiera.</p>
    
                        <p class="widget-title">Usos ilícitos</p>
    
                        <p><span>VoiceTelecom</span>  se reserva el derecho de eliminar las cuentas y servicios relacionados de los usuarios sin previa notificación cuando se presente alguno de los siguientes hechos:</p>
    
                        <ul>
                            <li> Que la marca y nombre de <span>VoiceTelecom</span> , el sitio, todas aplicaciones y servicios relacionados sean utilizados en perjuicio de <span>VoiceTelecom</span> , sus colaboradores, otros usuarios o el público en general.</li>
                            <li> Que se haga mal uso tecnológico del sitio, plataformas sistemas o programas.</li>
                            <li> Que sea utilizado para administrar actividades de índole criminal.</li>
                        </ul>
    
                        <p class="widget-title">Actualización de la Política de Privacidad</p>
    
                        <p><span>VoiceTelecom</span>  revisará la política de privacidad mínimo de con una periodicidad anual para agregar, eliminar o modificar información relevante para la misma, sin previo aviso y sin requisito de notificación para los usuarios. Esta revisión incluye los siguientes elementos:</p>
                        <p>
                            <ul>
                                <li> Se revisará que la política sea completa, comprensiva, completamente implementada y siempre accesible.</li>
                                <li> Se revisará que la política se haya generado conforme a los principios aceptados de privacidad de información.</li>
                                <li> Se revisará que los colaboradores de <span>VoiceTelecom</span>  la conozcan enteramente y que sea aplicada de forma absoluta.</li>
                                <li>Se revisará que se cumple con lo estipulado en la presente política en toda la extensión.</li>
                            </ul>
                        </p>
    
                        <p>Para comentarios, quejas, sugerencias o preguntas sobre la política de privacidad contáctanos en info@voicetelecom.net</p>
    
                        <!-- <p> 
                            <a href="http://www.voicetelecom.net/wp-content/uploads/2018/06/Derechos_Telecomunicaciones_web.pdf" target="_blank">
                            Derechos de usuarios de telecomunicaciones</a> 
                        </p> -->
    
                    </div>
                </div><!--ref-item end-->
            </div><!--reference-page end-->
            <div class="clearfix"></div>
        </div>
    </section><!--page-content end-->
</div>
</template>

<script>
import Banner from '@/components/layout/Banner.vue' 

export default {
    components:{
        Banner
    },
    mounted(){
        window.scrollTo(0, 0)
    },
    watch:{
        routeName: function () {
            window.scrollTo(0,4);
        }
    },
    created(){
        window.scrollTo(0, 10);
    },
    methods:{
        scrollBehavior (to, from, savedPosition) {
            if (to.hash) {
                return {
                    selector: to.hash,
                    behavior: 'smooth',
                }
            }
        }
    }
}
</script>

<style scoped>

.page-content{
    width: 100%;
    background-position: center;
    background-size: cover;
    min-height: 500px; 
    background-image: url("../../../public/images/background/fondo-transparente-vertical-hexagonal.png");
    background-repeat: no-repeat;
    position: relative;
    z-index: 0; 
}

.logo{
    width: 180px;
}

.ref-info{
    text-align: justify;
}

span{
    font-weight: 600;
}

li{
    color: #777777;
    font-size: 14px;
    line-height: 30px;
}

li:before{
    content: '\f00c';
    top: 0;
    left: 0;
    margin-left: 5px;
    font-size: 14px;
    font-family: fontawesome;
    color: #009c39;
}

a{
    text-decoration: none;
    color: #009c39;
    font-weight: 600;
}

p{
    padding:  15px
}
</style>