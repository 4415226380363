import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'
import About from '../views/About/index.vue'
import Services from '../views/Services/index.vue'
import Blog from '../views/Blog/index.vue'
import Support from '../views/Support/index.vue'
import Team from '../views/Team/index.vue'
import Contact from '../views/Contact/index.vue'
import NotFound from "../views/Error/";
import Test from '../views/Test/index.vue'
import Privacy from '../views/Privacy/index.vue';
//import InfoVoiceIp from '../views/InfoVoiceIp/index.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      pageTitle: "routes.home.page_title",
      hideBanner: true
    },
  },
  {
    path: '/nosotros',
    name: 'About',
    component: About,
    meta: {
      pageTitle: "routes.about.page_title",
      hideBanner: true
    },
  },
  /* {
    path: '/infoVoice',
    name: 'info',
    component: InfoVoiceIp,
    meta:{
      pageTitle: "routes.about.page_title",
      hideBanner: true
    },
  }, */
  // {
  //   path: '/blog',
  //   name: 'Blog',
  //   component: Blog,
  //   meta: {
  //     pageTitle: "routes.blog.page_title",
  //   },
  // },
  {
    path: '/servicios',
    name: 'Services',
    component: Services,
    meta: {
      pageTitle: "routes.services.page_title",
      hideBanner: true
    },
    children:[{
        path: '/voip',
        name: 'Voip',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/mia',
        name: 'Mia',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/callcenter',
        name: 'Callcenter',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/internet',
        name: 'Internet',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/alarma',
        name: 'Alarma',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/cableado',
        name: 'Cableado',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/antena',
        name: 'Antena',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/sip',
        name: 'Sip',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/ivr',
        name: 'Ivr',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
      },
      {
        path: '/tollfree',
        name: 'Tollfree',
        component: Services,
        meta: {
          pageTitle: "routes.services.page_title",
          hideBanner: true
        },
    }],
  },
  
  
  {
    path: '/soporte',
    name: 'Support',
    component: Support,
    meta: {
      pageTitle: "routes.support.page_title",
      hideBanner: true
    },
  },
  {
    path: '/contrataciones',
    name: 'Team',
    component: Team,
    meta: {
      pageTitle: "routes.get.page_title",
      hideBanner: true
    },
    children: [{
      path: '/ccv',
      name: 'ccv',
      component: Team,
      meta:{
          pageTitle: 'routes.about.page_title',
      },
  },
  {
      path: '/voip',
      name: 'voip',
      component: Team,
      meta:{
          pageTitle: 'routes.about.page_title',
      },
  },
  {
      path: '/alarm',
      name: 'alarm',
      component: Team,
      meta:{
          pageTitle: 'routes.about.page_title',
      }
  },]
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: Contact,
    meta: {
      pageTitle: "routes.contact.page_title",
      hideBanner: true
    },
  },
  // {
  //   path: '/pruebas',
  //   name: 'Test',
  //   component: Test,
  //   meta: {
  //     pageTitle: "routes.test.page_title",
  //   },
  // },
  {
    path: '/aviso-privacidad',
    name: 'Privacy',
    component: Privacy,
    meta: {
      pageTitle: "routes.privacy.page_title",
      hideBanner: true
    },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    name: "Error",
    meta: {
      pageTitle: 'routes.error.page_title',
      hideBanner: true
    }
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
