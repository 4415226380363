import { render, staticRenderFns } from "./Voip.vue?vue&type=template&id=7a3ff296&scoped=true"
var script = {}
import style0 from "./Voip.vue?vue&type=style&index=0&id=7a3ff296&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a3ff296",
  null
  
)

export default component.exports