<template>
    <div>
        <Banner :pageTitle="$route.meta.pageTitle" style="background-image: url(/images/background/fondo-obscuro-hexagonal.png); background-position: center;" />
        <section class="page-content mb-5">
            <div class="container">
                <div class="">
                    <div class="price-tables">
                        <div class="sec-title2">
                            <span>{{$t("pages.get.title_service")}}</span>
                            <h2>{{$t("pages.get.subtitle_internet")}}</h2>
                        </div>
                        <div class="price-tabless-content current" id="cctv">
                            <div class="price-tabless roll-in-left ">
                                <Cctv/>
                            </div>
                        </div>
                    </div>
                    <div class="price-tables  mt-5">
                        <div class="sec-title2">
                            <span>{{$t("pages.get.title_service")}}</span>
                            <h2>{{$t("pages.get.subtitle_alarm")}}</h2>
                        </div>
                        <div class="price-tabless-content current" id="alarm">
                            <div class="price-tabless">
                                <Alarm/>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import Cctv from '@/components/prices/Cctv.vue'
import Voip from '@/components/prices/Voip.vue'
import Alarm from '@/components/prices/Alarm.vue'
import Banner from '@/components/layout/Banner.vue'

export default {
    components: {
        Cctv,
        Voip,
        Alarm,
        Banner
    },
    mounted(){
        window.scrollTo(0, 0)
    },
    watch:{
        routeName: function () {
            window.scrollTo(0,4);
        }
    },
    created(){
        window.scrollTo(0, 10);
    },
    methods:{
        scrollBehavior (to, from, savedPosition) {
            if (to.hash) {
                return {
                    selector: to.hash,
                    behavior: 'smooth',
                }
            }
        }
    }
}
</script>

<style scoped>
.page-content{
    width: 100%;
    background-position: center;
    background-size: cover;
    min-height: 500px; 
    background-image: url("../../../public/images/background/fondo-transparente-vertical-hexagonal.png");
    background-repeat: no-repeat;
    position: relative;
    z-index: 0; 
}
.sec-title2{
    color: #222222;
    font-size: 40px;
    position: relative;
    padding-bottom: 39px;
}
.sec-title2 > span {
    display: inline-block;
    color: #000;
    font-size: 29px;
    font-weight: 100;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.roll-in-left {
	-webkit-animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-3-31 10:20:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
            transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
            transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

</style>