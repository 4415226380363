<template>
<div class="principal">
    <div :class="[(nameRoute!='Contact')?'content-contact':'content-noHome']">
        <div class="container pt-5">
            <div class="back-form border-form">
                <!-- DIV PADRE -->
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 c-white">
                        <div class="sec-title text-left">
                            <h2 class="pt-5 p-2"> {{$t("components.contact_form.title")}} </h2>
                            <h3 class="pl-5 pb-0 pt-5 subtitle">
                                <span>{{$t("components.contact_form.title_contact")}}</span>
                            </h3>
                        </div>
                        <!-- DIV HIJO -->
                        <div class="info row">
                            <!-- LOCATION -->
                            <div class="col-lg-12 col-md-4 col-sm-4 col-4 b-hidden">
                                <!-- DIV NIETO -->
                                <div class="row">
                                    <div class="col-lg-5 col-md-12 col-sm-12">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <h3>{{$t("components.contact_form.location")}}</h3>
                                    </div>
                                    <div class="col-lg-5 col-md-12 col-sm-12 contact-info">
                                        <p class="text-initial">
                                            Cancún, Quintana <br> Roo México
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- MAIL -->
                            <div class="col-lg-12 col-md-4 col-sm-4 col-4 b-hidden">
                                <div class="row">
                                    <div class="col-lg-5 col-md-12 col-sm-12">
                                        <i class="fas fa-envelope"></i>
                                        <h3>{{$t("components.contact_form.mail")}}</h3>
                                    </div>
                                    <div class="col-lg-5 col-md-12 col-sm-12 contact-info">
                                        <p class="text-initial">
                                            contacto@voicetelecom.net
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- PHONE -->
                            <div class="col-lg-12 col-md-4 col-sm-4 col-4 b-hidden">
                                <div class="row">
                                    <div class="col-lg-5 col-md-12 col-sm-12">
                                        <i class="fas fa-phone-alt"></i>
                                        <h3>{{$t("components.contact_form.phone")}}</h3>
                                    </div>
                                    <div class="col-lg-5 col-md-12 col-sm-12  contact-info">
                                        <p class="text-initial">
                                            Cancún: 998 500 2200 <br> {{$t("components.contact_form.contrynumber")}} <span class="salto"></span>800 483 0055
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- responsive ms-->
                            <!-- LOCATION -->
                            <div class="col-12 r-hidden">
                                <!-- DIV NIETO -->
                                <div class="row">
                                    <div class="col-6">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <h3>{{$t("components.contact_form.location")}}</h3>
                                    </div>
                                    <div class="col-6 contact-info">
                                        <p class="text-initial pb-2">
                                            Cancún, Quintana <br/> Roo México
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- PHONE -->
                            <div class="col-12 r-hidden">
                                <div class="row">
                                    <div class="col-6">
                                        <i class="fas fa-phone-alt"></i>
                                        <h3>{{$t("components.contact_form.phone")}}</h3>
                                    </div>
                                    <div class="col-6 contact-info">
                                        <p class="text-initial">
                                            Cancún: 998 500 2200 <br> {{$t("components.contact_form.contrynumber")}}800 483 0055
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- MAIL -->
                            <div class="col-12 r-hidden">
                                <div class="row">
                                    <div class="col-6">
                                        <i class="fas fa-envelope"></i>
                                        <h3>{{$t("components.contact_form.mail")}}</h3>
                                    </div>
                                    <div class="col-6 contact-info">
                                        <p class="text-initial">
                                            contacto@voicetelecom.net
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- responsive xs -->
                            <div class="l-hidden">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <i class="fas fa-phone-alt"></i><h3>{{$t("components.contact_form.phone")}} <br>Cancún: 998 500 2200 
                                        <br>{{$t("components.contact_form.contrynumber")}} 800 483 0055 </h3>
                                    </div>
                                    <div class="col-12">
                                        <i class="fas fa-envelope"></i><h3>{{$t("components.contact_form.mail")}} <br> contacto@voicetelecom.net </h3>
                                    </div>
                                    <div class="col-12" >
                                        <i class="fas fa-map-marker-alt"></i><h3>{{$t("components.contact_form.location")}} <br>Cancún, Quintana Roo México</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 c-green">
                        <div class="contact-pg p-3" id="mc_embed_signup">
                            <div id="mc_embed_signup">
                                <form action="https://voicetelecom.us5.list-manage.com/subscribe/post?u=3edcd8c41b434564514f00d31&amp;id=940c8f8832" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                    <div id="mc_embed_signup_scroll">
                                        <div class="row p-3 justify-content-center">
                                            <!-- NAME -->
                                            <div class="col-lg-6 col-sm-6 col-6 f-hidden">
                                                <input type="text" :placeholder="$t('components.contact_form.input.name')" name="FNAME" class="required" id="mce-FNAME ">
                                            </div>
                                            <div class="col-12 f-hidden-r">
                                                <input type="text" :placeholder="$t('components.contact_form.input.name')" name="FNAME" class="required" id="mce-FNAME ">
                                            </div>
                                            <!-- LAST NAME -->
                                            <div class="col-lg-6 col-sm-6 col-6 f-hidden">
                                                <input type="text" :placeholder="$t('components.contact_form.input.last_name')" name="LNAME" class="required" id="mce-LNAME">
                                            </div>
                                            <div class="col-12 f-hidden-r">
                                                <input type="text" :placeholder="$t('components.contact_form.input.last_name')" name="LNAME" class="required" id="mce-LNAME">
                                            </div>
                                            <!-- MAIL -->
                                            <div class="col-lg-12 col-sm-6 col-6 f-hidden">
                                                <input type="email" :placeholder="$t('components.contact_form.input.mail')" name="EMAIL" class="required email" id="mce-EMAIL">
                                            </div>
                                            <div class="col-12 f-hidden-r">
                                                <input type="email" :placeholder="$t('components.contact_form.input.mail')"  name="EMAIL" class="required email" id="mce-EMAIL">
                                            </div>
                                            <!-- PHONE -->
                                            <div class="col-lg-6 col-sm-6  col-6 f-hidden">
                                                <input type="tel" pattern="[0-9]{1,15}" name="PHONE" class="required" :placeholder="$t('components.contact_form.input.phone')"  id="mce-PHONE">
                                            </div>
                                            <div class="col-12 f-hidden-r">
                                                <input type="tel" pattern="[0-9]{1,15}" name="PHONE" class="required" :placeholder="$t('components.contact_form.input.phone')"  id="mce-PHONE">
                                            </div>
                                            <!-- SELECT -->
                                            <div class="col-lg-6 col-sm-12 ">
                                                <select name="MMERGE6" class="required" id="mce-MMERGE6">
                                                    <option selected value="Información sobre:">{{$t("components.contact_form.input.info.select")}}</option>
                                                    <option value="Alarmas y Monitoreo">{{$t("components.contact_form.input.info.alarm")}}</option>
                                                    <option value="Internet">{{$t("components.contact_form.input.info.internet")}}</option>
                                                    <option value="Asistente Virtual">{{$t("components.contact_form.input.info.mia")}}</option>
                                                    <option value="Call Centers">{{$t("components.contact_form.input.info.cc")}}</option>
                                                    <option value="SIP Trunking">{{$t("components.contact_form.input.info.sip")}}</option>
                                                    <option value="Torres y Antenas">{{$t("components.contact_form.input.info.towers")}}</option>
                                                    <option value="Smart IVR">{{$t("components.contact_form.input.info.ivr")}}</option>
                                                    <option value="Toll Free">{{$t("components.contact_form.input.info.tf")}}</option>
                                                    <option value="Cableado">{{$t("components.contact_form.input.info.cabling")}}</option>
                                                </select>
                                            </div>
                    
                                            <div id="mce-responses" class="clear">
                                                <div class="response" id="mce-error-response" style="display:none"></div>
                                                <div class="response" id="mce-success-response" style="display:none"></div>
                                            </div>  
                                            <input type="submit" :value="$t('components.contact_form.input.button')" name="subscribe" id="mc-embedded-subscribe" class="button">
                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div :class="[(nameRoute!='Contact')?'bottom-contact':'bottom-noHome']">
    </div>
</div>
</template>

<script>
    export default{
        computed:{
            nameRoute(){
                return this.$route.name;
            }
        }
    }
</script>

<style scoped>

.contact-info {display: block;}

/* FONDOS */
.principal{
    overflow: hidden;
    /* padding-bottom: 20px;  */
}
.content-contact{
    width: 100%;
    height: 50vh;
    background-position: center;
    background-size: cover;
    min-height: 500px; 
    background-image: url("../../../public/images/background/medios-de-pago-con-paypal-mercado-pago-oxxo-pay-tarjeta-de-credito-debito.jpg");
    background-repeat: no-repeat;
    position: relative;
    z-index: 1; 
}
.content-contact::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(to right top, #05193799, #004d7a99, #00879399, #00bf7299, #a8eb1299); */
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
}
.content-noHome{
    background-image: none;
}
.back-form{
    width: 100%;
    height: 100%;
    background-position: inherit;
    background-size: cover;
    min-height: 500px; 
    background-image: url("../../../public/images/contact/fondo-size.png");
    background-repeat: no-repeat;
    position: relative;
    z-index: 1; 
}
.bottom-contact{
    background-color: #272727;
    height: 20vh;
    display: block;
}
/* Estilos de forma */
.border-form{
    border-radius: 23px !important;
}
/* Estilos de formulario */
input, select{
    width: -webkit-fill-available;
    padding: 15px;
    position: relative;
    padding-bottom: 3%;
    min-height: 50px;
    margin-bottom: 15px;
    border-radius: 15px !important;
    background: bottom;
}
/* Estilos de letras en informacion */
.sec-title > span{
    font-size: 25px;
}
.sec-title {
	text-align: center;
	margin-bottom: 20px !important;
}
.sec-title > h2:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -74px;
    width: 72%;
    height: 5px;
    transform: translateX(-50%);
}
.sec-title h2{
    font-weight: 800;
}
h3{
    font-size: 25px;
}
.info h3{
    margin: 20px 0;
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
}
.info i{
    font-size: 40px
}
.contact-info{
    padding: 10px 0px;
}
p{
    font-size: 20px;
}
.text-initial{
    text-align: initial;
}
/* Estilos de contacto */
#mc_embed_signup form {
    display: block;
    position: relative;
    text-align: left;
    padding: 140px 30px !important;
}
#mc_embed_signup {
    border-radius: 15px;
}
#mc_embed_signup h2 {
    font-weight: bold;
    padding: 0;
    margin: 10px 0;
    font-size: 1.4em;
    border-radius: 10px;
}
#mc_embed_signup input {
    border: 1px solid #fff;
    border-radius: 3px;
    color: #fff;
    }
#mc_embed_signup select {
    border: 1px solid #fff;
    border-radius: 3px;
    }
a{
    text-decoration: none;
    color: #777777;
}
.green-link:hover i {
    color: #009c39;
}
.green-link:hover a {
    color: #009c39;
}
#mc_embed_signup .mc-field-group input {
    padding: 12px 0;
}
#mc_embed_signup {
    background: transparent !important;
    clear: left;
}
#mc_embed_signup .mc-field-group select {
    display: inline-block;
    width: 70%;
    padding: 12px 0;
    margin-bottom: 2px;
}
#mc_embed_signup .datefield input, #mc_embed_signup .phonefield-us input {
    /* width: 87px; */
    padding: 5px 0 2px 0;
}
#mc_embed_signup .mc-field-group {
    width: 96% !important;
}
.clear .lnk-default{
    /* background-color: #333333; */
    color: #fff;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}
input:focus::-webkit-input-placeholder {
    color: #242424;
}
select { color:#fff; }
option { color:#242424; background-color:rgba(255, 255, 255, .8) !important; } 
#mc_embed_signup .button {
    background-color: #272727;
    padding: 0 45px;
    font-weight: 600;
    font-size: 15px;
    border: 0 none;
    border-radius: 4px;
    transition: all 0.23s ease-in-out 0s;
    color: #FFFFFF;
    cursor: pointer;
}
#mc_embed_signup .button:hover{
    background-color: rgba(39,39,39,.9)
}
/* HIDDEN STUFF */
.r-hidden{
    display: none;
}
.l-hidden{
    display: none !important;
}
.f-hidden-r{
    display: none !important;
}

/* RESPONSIVE */
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1920px) {

    .content-contact{
        height: 60vh;
    }

    .bottom-contact{
    height: 15vh;
}

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1440px) {

    /* FONDOS */
    .content-contact{
        height: 60vh;
    }
    .bottom-contact{
        height: 15vh;
    }

    /* ESTILOS DE INFO */
    .info i {
        font-size: 30px;
    }

    P{
        font-size: 18px;
    }

    .sec-title span{
        font-size: 22px;
    }

    /* FORMA CONTACTO */
    #mc_embed_signup form {
        padding: 120px 30px !important;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1024px) {
    /* ESTILOS DE INFO */
    .sec-title > h2 {
    font-size: 32px;
    }
    .info i {
        font-size: 30px;
    }
    P{
        font-size: 18px;
    }
    .subtitle{
        padding-right: 18px;
    }
    .sec-title > h2:after {
    left: 16%;
}
    /* FORMA CONTACTO */
    #mc_embed_signup form {
        padding: 120px 30px !important;
    }
    .back-form{
        background-position: center !important;
        background-size: cover;
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
    /* FONDOS */
    .back-form{
        background-image: url("../../../public/images/contact/testcolor-hor.png");
        padding: 20px;
    }
    .border-form{
        border-radius: 23px !important;
    }

    /* FORM CONTACT */
    #mc_embed_signup form {
    padding: 55px 30px !important;
    }
    /* FONDOS */
    .content-contact{
        height: 70vh;
    }
    .bottom-contact{
        height: 15vh;
    }

    /* TEXTO INFO  */
    .text-initial {
    text-align: center;
    font-size: 15px;
    }
    .pt-5 {
    padding-top: 1rem!important;
    }
    .sec-title > h2 {
    padding-top: 0px !important;
}

    .info h3 {
    margin: 8px 0;
    font-weight: 800;
    font-size: 16px;
    }

    .contact-pg{
        padding: 0px !important;
    }
    .sec-title > h2:after {
    left: 22%;
    }
    /* FORM CONTACT */
    #mc_embed_signup form {
    padding: 40px 30px 0px 30px !important;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {

    /* TODO: FONDOS */
    .content-contact{
        height: 70vh;
    }
    .bottom-contact{
        height: 15vh;
    }

    .container, .container-sm {
    max-width: 640px !important;
}

    /* TEXTO INFO  */
    .text-initial {
    text-align: center;
    font-size: 15px;
    }
    .pt-5 {
    padding-top: 1rem!important;
    }
    .sec-title > h2 {
    padding-top: 0px !important;
}

    .info h3 {
    margin: 8px 0;
    font-weight: 800;
    font-size: 16px;
    }

    .contact-pg{
        padding: 0px !important;
    }
   
    /* FORM CONTACT */
    #mc_embed_signup form {
    padding: 40px 30px 0px 30px !important;
    }

    .text-initial[data-v-6f80fa26] {
    text-align: center;
    font-size: 13px;
    }
    .salto{
        display: block;
    }
    .sec-title > h2:after {
    left: 19% !important;
}


}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 575px) {
    /* FONDOS */
    .back-form{
    background-position: inherit !important;
    background-size: contain;
    background-image: url("../../../public/images/contact/test-hor.png") !important;
    }
    .content-contact{
        height: 30vh;
    }
    .bottom-contact{
        height: 15vh;
    }

    /* ESTILO DE TEXTO INFO */
    .sec-title > h2 {
        font-size: 24px;
    }
    .info i{
        font-size: 20px;
    }
    .info h3{
        font-size: 12px;
    }
    .sec-title span {
        font-size: 18px;
    }
    .sec-title > h2:after {
    left: 18% !important;
    }

    /* CONTACT FORM */
    #mc_embed_signup form[data-v-6f80fa26] {
    padding: 0px 30px 0px 30px !important;
}

}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 557px) {
    /* FONDOS */
    .c-white{
        background-color: rgba(255, 255, 255, .8) !important;
        border-radius: 20px;
    }
    .c-green{
        background-color: #009c39 !important;
        border-radius: 20px;
    }
    .back-form{    
    background-size: 0 0;
    }
    .content-contact{
        height: 70vh !important;
    }
    .bottom-contact{
        height: 15vh;
    }
    /* ESTILO DE TEXTO INFO */
    .sec-title > h2 {
        font-size: 24px;
    }
    .info i{
        font-size: 20px;
    }
    .info h3{
        font-size: 12px;
    }
    .sec-title span {
        font-size: 18px;
    }
    .subtitle{
        font-size: 15px !important;
        padding: 5px;
    }
    .sec-title > h2:after {
    left: 18% !important;
}
    /* CONTACT FORM */
    #mc_embed_signup form{
    padding: 0px 30px 0px 30px !important;
    }
    /* HIDDEN STUFF */
    .r-hidden{
    display: none !important;
    }
    .b-hidden{
        display: none !important;
    }
    .l-hidden{
        display: block !important;
    }
    .numcol{
        columns: 2;
    }



}


@media only screen and (max-width:427px) {

    .content-contact{
        height: 90vh;
    }

    .bottom-contact{
    height: 45vh;
}

    .f-hidden{
        display: none !important;
    }
    .f-hidden-r{
        display: block !important;
    }
}

</style>