<template>
<div class="page-content ">
    <div class="">
        <!-- <IconsPrueba/> -->
        <Preloader/>
    </div>

</div>
</template>

<script>
// import IconsPrueba from '@/components/layout/IconsPrueba.vue' 
import Preloader from '@/components/layout/Loader.vue' 
export default {
    components:{
        Preloader
    },
}
</script>

<style scoped>

</style>