<template>
    <div class="fondo-about" style="background-color: #fff">
        <Banner :pageTitle="$route.meta.pageTitle" style="background-image: url(/images/background/fondo-obscuro-hexagonal.png); background-position: center;"/>
        <div>
            <section class="abt-block">
                    <div class="about-section">
                        <div class="container first-capa">
                            <div class="row" data-aos="zoom-out">
                                <div class="col-lg-5">
                                    <div class="container">
                                        <div class="about-text">
                                            <h2>{{$t("pages.about.subtitle")}}</h2>
                                            <p>
                                                {{$t("pages.about.description")}} 
                                                <span>{{$t("pages.about.description_1")}} </span> {{$t("pages.about.description_2")}}
                                            </p>
                                            <p>
                                                {{$t("pages.about.description1")}}  <span> {{$t("pages.about.description1_1")}} </span>
                                            </p>
                                            <p>
                                                {{$t("pages.about.description2")}}  <span> {{$t("pages.about.description2_2")}} </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="pad-img">
                                        <img class="us-img slide-in-blurred-top" src="../../../public/images/about/nuestros-clientes-satisfechos-con-soluciones-de-negocio-rentables.png" alt="nuestros clientes satisfechos con soluciones de negocio rentables">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cut-form pt-5">
                            <div class="container">
                                <section class="space">
                                    <div class="row justify-content-center pb-3 responsive-hidde">
                                    <div class="col-lg-4 col-md-12 col-sm-12 pb-3">
                                        <div class="green">
                                            <img class="icon-about uno" src="../../../public/images/about/icono-capacitación-certificaciones-amplia-experiencia-ingenieria-de-proyectos-en-telecomunicacion.png" alt="icono-capacitación-certificaciones-amplia-experiencia-ingenieria-de-proyectos-en-telecomunicacion ">
                                            <p class="pt-3">
                                                {{$t("pages.about.tag1")}} 
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12 pb-3">
                                        <div class="green">
                                            <img class="icon-about dos" src="../../../public/images/about/icono-atencion-personalizada-a-clientes.png" alt="icono atencion personalizada a clientes">
                                            <p class="pt-5 pb-5">
                                                {{$t("pages.about.tag2")}} 
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12 pb-3">
                                        <div class="green">
                                            <img class="icon-about tres" src="../../../public/images/about/icono-mejor-propuesta-de-valor.png" alt="icono mejor propuesta de valor">
                                            <p class="pt-3">
                                                {{$t("pages.about.tag3")}} 
                                            </p>
                                        </div>
                                    </div>
                                    </div>
                                    <!-- RESPONSIVE -->
                                    <div class="row justify-content-center pb-3 no-res">
                                    <div class="col-lg-4 col-md-12 col-sm-12 pb-3">
                                        <div class="green1">
                                            <img class="icon-about uno" src="../../../public/images/about/icono-capacitación-certificaciones-amplia-experiencia-ingenieria-de-proyectos-en-telecomunicacion.png" alt="icono-capacitación-certificaciones-amplia-experiencia-ingenieria-de-proyectos-en-telecomunicacion ">
                                            <p class="pt-3">
                                                {{$t("pages.about.tag1")}} 
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12 pb-3">
                                        <div class="green1">
                                            <img class="icon-about dos" src="../../../public/images/about/icono-atencion-personalizada-a-clientes.png" alt="icono atencion personalizada a clientes">
                                            <p class="pt-5 pb-5">
                                                {{$t("pages.about.tag2")}} 
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 col-sm-12 pb-3">
                                        <div class="green1">
                                            <img class="icon-about tres" src="../../../public/images/about/icono-mejor-propuesta-de-valor.png" alt="icono mejor propuesta de valor">
                                            <p class="pt-3">
                                                {{$t("pages.about.tag3")}} 
                                            </p>
                                        </div>
                                    </div>
                                    </div>
                                </section>
                            </div>
                            <div class="row pt-5 mt-5 resp-contact">
                                <div class="col-lg-6 col-md-12 col-sm-12 pt-5 top-contact">
                                    <p class="contact left">
                                        <i class="fas fa-phone-alt"></i>
                                        <span class="pt-2">Cancún: 998 500 2200</span>
                                        <span> {{$t("components.contact_form.contrynumber")}} 800 483 0055</span>
                                    </p>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 pt-5 top-contact">
                                    <p class="contact right"> 
                                        <i class="fas fa-envelope"></i>
                                        <span class="pt-2">contacto@voicetelecom.net</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            <!-- <Clients/> -->
        </div>
    </div>
</template>


<script>
import Clients from '@/components/layout/Clients.vue' 
import Banner from '@/components/layout/Banner.vue' 
export default {
    components:{
        Clients,
        Banner
    },
    
  mounted(){
    window.scrollTo(0, 0)
  },
  watch:{
    routeName: function () {
        window.scrollTo(0,4);
    }
  },
  created(){
    window.scrollTo(0, 10);
  },
  methods:{
      scrollBehavior (to, from, savedPosition) {
          if (to.hash) {
              return {
                  selector: to.hash,
                  behavior: 'smooth',
              }
          }
      }
  }
}
</script>


<style scoped>
/* FONDOS */
.fondo-about{
    /* width: 100%; */
    background-position: inherit;
    background-size: cover;
    min-height: 500px; 
    background-image: url("../../../public/images/about/rect2.png");
    background-repeat: no-repeat;
    position: relative;
    z-index: 1; 
}
.fondo-about::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: opacity(.6);
    background-image: url("../../../public/images/background/fondo-transparente-vertical-hexagonal.png");
    z-index: -1;
}
.space{
    height: 50vh;
}

/* PRIMER SECCION */
p{
    text-align:center
}
span{
    font-weight: 600;
}
.us-img{
        padding: 10px 0;
        width: 755px;
        filter: drop-shadow(16px 15px 8px rgba(0, 0, 0, 0.7));
    }
.pad-img{
        padding: 3rem!important;
}
/* SEGUNDA SECCION */
.uno{
    width: 170px;
}
.dos{
    width: 170px;
}
.tres{
    width: 150px;
}
.green{
    background-color: #2a9841;
    margin: 0 60px;
    z-index: 0;
    width: 300px;
    border-radius: 50px;
    padding: 54px 28px;
    box-shadow: 17px 21px 20px -13px rgba(0,0,0,0.54);
    -webkit-transition:all 1.9s ease;
    -moz-transition:all 1.9s ease;
    -o-transition:all 1.9s ease;
    -ms-transition:all 1.9s ease;
}
.green1{
    background-color: #2a9841;
    margin: 0 60px;
    /* z-index: 0; */
    border-radius: 50px;
    padding: 30px 80px;
    box-shadow: 17px 21px 20px -13px rgba(0,0,0,0.54);
    -webkit-transition:all 1.9s ease;
    -moz-transition:all 1.9s ease;
    -o-transition:all 1.9s ease;
    -ms-transition:all 1.9s ease;
}
.green:hover {
    position: absolute;
    background-color: #115740;
    /* z-index: 3 !important; */
    transform:scale(1.2);
}
.green1:hover {
    /* position: absolute; */
    background-color: #115740;
    /* z-index: 3 !important; */
    /* transform:scale(1.2); */
}
.green p{
    color: #fff
}
.green1 p{
    color: #fff
}
.no-res{
    display: none;
}
.top-contact{
    padding-top: 24px;
}
/* BOTTOM */
.contact span {
    display: block;
    font-weight: 400;
    font-size: 20px;
}
.contact i{
    color: #009c39;
    font-size: 40px;
}
.right{
    padding-right: 450px;
}
.left{
    padding-left: 450px;

}

/* RESPONSIVIDAD */
@media only screen and (max-width: 2560px) {
    .space{
        height: 35vh;
    }
    .about-text > p {
    font-size: 25px;
    }

    .green p{
    font-size: 19px;
    }
    .uno{
    width: 130px;
    }
    .dos{
        width: 150px;
    }
    .tres{
        width: 130px;
    }
}
@media only screen and (max-width: 1440px) {
    .space{
        height: 50vh;
    }
    .right{
    padding-right: 350px;
    }
    .left{
        padding-left: 350px;
    }
    .us-img{
        width: 625px;
    }
}
@media only screen and (max-width: 1199px) {
    .about-text p{
        font-size: 20px;
    }
    .right{
    padding-right: 250px;
    }
    .left{
        padding-left: 250px;
    }
    .us-img{
        width: 560px;
    }
    .green{
        margin: 0 !important;
    }
}
@media only screen and (max-width: 1080px) {
    .us-img{
        width: 500px;
    }
}
@media only screen and (max-width: 1038px){
    .right{
    padding-right: 0px !important;
}
    .left{
        padding-left: 0px !important;

}
}
@media only screen and (max-width: 991px) {
    .space{
        height: auto;
    }
    .green{
        background-color: #2a9841;
        margin: 0 60px;
        z-index: 0;
        width: auto;
        border-radius: 50px;
        padding: 54px 28px;
    }
    .green:hover {
    position: absolute;
    background-color: #115740;
    z-index: 3 !important;
    transform:scale(1.2);
    }
    .responsive-hidde{
        display: none !important;
    }
    .no-res{
    display: block ;
    }
    
}
@media only screen and (max-width: 586px) {
    .green1{
    padding: 30px 40px;
    }
    .pad-img{
        padding: 10px 0px 50px 0 !important;
    }

}
@media only screen and (max-width: 528px) {
    .us-img{
        width: 345px !important;
    }
}
@media only screen and (max-width: 480px){
    .about-text p{
        font-size: 20px !important;
    }
    .resp-contact{
        padding-top: 10px !important;
    }
}

/* animaciones */

.slide-in-blurred-top {
	animation: slide-in-blurred-top 0.9s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 0%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
</style>