<template>
<div>
    <Banner  :pageTitle="$route.meta.pageTitle" style="background-image: url(/images/background/fondo-obscuro-hexagonal.png);" />
    <section class="page-content">
        <div class="container">
            <div class="blog-style2">
                <div class="row justify-content-center">
                    <!-- VOIP -->
                    <div id="voip" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr">
                        <Voip/>
                    </div>
                    <!-- MIA -->
                    <div id="mia" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr">
                        <Mia/>
                    </div>
                    <!-- CALL CENTER -->
                    <div id="callcenter" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr" >
                        <Callcenter/>
                    </div>
                    <!-- INTERNET -->
                    <div id="internet" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr" >
                        <Internet/>
                    </div>
                    <!-- ALARMAS -->
                    <div id="alarma" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr" >
                        <Alarma/>
                    </div>
                    <!-- CABLEADO -->
                    <div id="cableado" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr" >
                        <Cableado/>
                    </div>
                    <!-- ANTENAS -->
                    <div id="antena" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr">
                        <Antena/>
                    </div>
                    <!-- SIP TRUNKING -->
                    <div id="sip" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr" >
                        <Sip/>
                    </div>
                    <!-- IVR -->
                    <div id="ivr" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr" >
                        <Ivr/>
                    </div>
                    <!-- TOLL FREE -->
                    <div id="tollfree" class="col-lg-4 col-md-12 col-sm-12 size tilt-in-fwd-tr" >
                        <Tollfree/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>


<script>
import Banner from '@/components/layout/Banner.vue' 
import Voip from '@/components/services/Voip.vue' 
import Mia from '@/components/services/Mia.vue' 
import Callcenter from '@/components/services/Callcenter.vue' 
import Internet from '@/components/services/Internet.vue' 
import Alarma from '@/components/services/Alarma.vue' 
import Cableado from '@/components/services/Cableado.vue' 
import Antena from '@/components/services/Antena.vue' 
import Sip from '@/components/services/Sip.vue' 
import Ivr from '@/components/services/Ivr.vue' 
import Tollfree from '@/components/services/Tollfree.vue' 
export default {
    components:{
        Banner,
        Voip,
        Mia,
        Callcenter,
        Internet,
        Alarma,
        Cableado,
        Antena,
        Sip,
        Ivr,
        Tollfree
    },
    
    data(){
        return{
            sectionName: ''
        }
    },
    watch:{
        sectionName: function () {
            document
            .getElementById(this.sectionName)
            .scrollIntoView({ block: "nearest", behavior: "smooth" });
            }
    },
    beforeRouteEnter(to, from, next) {
        next((self) => {
        let sectionId = to.name.toLowerCase();
        self.sectionName = sectionId;
        });
    },
    beforeRouteUpdate(to, from, next) {
        let sectionId = to.name.toLowerCase();
        this.sectionName = sectionId;
        next()
    },
    created(){
        window.scrollTo(0, 10);
    },
    methods: {
        goTo(id){
        document
        .getElementById(id)
        .scrollIntoView({ block: "nearest", behavior: "smooth" });
        },
    },
};
</script>

<style scoped>
.page-content{
    width: 100%;
    background-position: center;
    background-size: cover;
    min-height: 500px; 
    background-image: url("../../../public/images/background/fondo-transparente-vertical-hexagonal.png");
    background-repeat: no-repeat;
    position: relative;
    z-index: 0; 
}

.tilt-in-fwd-tr {
	-webkit-animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-25 13:0:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
            transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
            transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

</style>