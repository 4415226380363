<template>
    <div class="home">
      <!-- <Popup/> -->
      <Carousel/>
      <div data-aos="flip-left" data-aos-duration="1000">
        <Icons/>
      </div>
      <div data-aos="fade-left" data-aos-duration="1000">
        <Clients/>
      </div>
      <div data-aos="zoom-in">
        <Comments/>
      </div>
      <ContactForm2/>
    </div>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/layout/Carousel'
// import Popup from '@/components/layout/Popup'
import Icons from '@/components/layout/Icons'
import Clients from '@/components/layout/Clients'
import Comments from '@/components/layout/Comments'
import ContactForm2 from '@/components/contact/ContactForm2'


export default {
  name: 'Home',
  components: {
    Carousel,
    Icons,
    Clients,
    Comments,
    ContactForm2
  },
  mounted(){
    window.scrollTo(0, 0)
    },
    watch:{
        routeName: function () {
            window.scrollTo(0,4);
        }
    },
    created(){
        window.scrollTo(0, 10);
    },
    methods:{
        scrollBehavior (to, from, savedPosition) {
            if (to.hash) {
                return {
                    selector: to.hash,
                    behavior: 'smooth',
                }
            }
        }
    }
}
</script>

<style scoped>
.home{
  /* background-color: #f5f5f5 !important; */
}

.tilt-in-right-1 {
	-webkit-animation: tilt-in-right-1 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: tilt-in-right-1 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-15 11:3:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-right-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-right-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
            transform: rotateX(-30deg) translateX(300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-right-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
            transform: rotateX(-30deg) translateX(300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}

</style>